import React from "react"
import photoicon from "../images/photoicon.png"
import { withPrefix } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Helmet from "react-helmet"
import load from 'load-script'
import Typewriter from 'typewriter-effect'
import ArticlePreview from "../components/articlePreview"
import { Row, Col, Container, Image, ListGroup, InputGroup, FormControl, Button } from "react-bootstrap"

var __mouthParam = 0
var __mouthParamCoefficient = 1
var __speaking = false

const Chatbot = () => {
    const [chats, setChats] = React.useState([])
    const [counter, setCounter] = React.useState(-1000000)
    const [disabled, setDisabled] = React.useState(false)
    const [message, setMessage] = React.useState("")
    const messagesEndRef = React.useRef(null)

    const handleMessageChange = event => {
        var f = event.target.value
        setMessage(f);
    }

    const keyPressCheck = (event) => {
        if (disabled) return;
        if (event.key === 'Enter') {
            getNewBotAnswer();
        }
    }


    const onTouchArea = (area) => {

        if (area === "Boobs") {
            setCRB5Response("Please don't touch me there, be respectful.")

        }
        if (area === "Head") {
            setCRB5Response("Are you trying to pat my head? That's nice!")
        }
    }

    const setCRB5Response = (message) => {
        __speaking = true
        const c = chats
        console.log(counter)
        c.push({ sender: "CRB5", message: message })
        setCounter(counter + Math.random())
        setDisabled(false)
        setTimeout(() => {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
        }, 500)

    }

    const voiceSync = (dTime) => {
        if (__speaking) {
            if (__mouthParam >= 1) {
                __mouthParamCoefficient = -1
            }
            else if (__mouthParam <= 0) {
                __mouthParamCoefficient = 1
            }
            __mouthParam = __mouthParam + (dTime * __mouthParamCoefficient * 2.6)
            return __mouthParam
        }
        return 0
    }

    const capturePhoto = () => {

        L2Dwidget.captureFrame((e) => {
            var link = document.createElement('a');
            document.body.appendChild(link);
            link.setAttribute('type', 'hidden');
            link.href = e;
            link.download = 'CRB-5.png';
            link.click();
        }

        );
    }

    React.useEffect(() => {
        load(withPrefix('live2dcubismcore.min.js'), (err, script) => {
            if (err) {
                console.error("Error loading Live 2D core")
            }
            else {
                load(withPrefix('L2Dwidget.min.js'), (err, script) => {
                    if (err) {
                        console.error("Error loading Live 2D widget")
                    }
                    else {
                        L2Dwidget.init({
                            model: {
                                jsonPath: '../resources/crb-5/crb-5.model3.json',
                                eyeBlink: true,
                                lipsync: true,
                                lipsyncFunction: voiceSync
                            },
                            scale: 2.0,
                            opacity: 1.0,
                            onTouchHitArea: onTouchArea

                        })
                    }
                })
            }
        })


    }, [])

    const getNewBotAnswer = () => {
        if (message == "" || message == null) return
        setDisabled(true)
        const c = chats
        c.push({ message: message.toLowerCase(), sender: "You" })
        fetch("/api/chatbot", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                message: message
            })
        })
            .then(response => {
                console.log(response)
                if (response.status !== 200) {
                    // something went wrong

                    setDisabled(false)
                    alert("There was an error when communicating with the server, please try again later")
                    return;
                }

                response.json().then(data => {
                    console.log(data)
                    setCRB5Response(data.message)

                })
            })
        setMessage("")

    }



    return (
        <Layout pageInfo={{ pageName: "CRB-5 chat" }}>
            <SEO title="Chat with CRB-5!"
            description="Crimson R Games publishes apps and games developed mostly solo by Aldo Pedro Rangel Montiel(aldoram5). Find games, apps and devlogs."
            keywords={[`CRB-5`, `games`, `indie`, `chatbot`, 'aldoram5', 'crimsonrgames']}  />
            <Row >
                <Col>
                    <Container className="mt-4 ">
                        <h2>Chat with CRB-5!</h2>
                        <hr></hr>
                        <div>
                            <Row>
                                <Col md={{ span: 4, offset: 4 }} xs={{ span: 12 }}>
                                    <Container className="text-center">

                                        <div id="live2d-widget" className="chatbot_preview">
                                            <canvas id="live2dcanvas" width="300" height="300"></canvas>
                                        </div>

                                    </Container>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col md={{ span: 1, offset: 8 }} xs={{ span: 12 }}>
                                    <Button onClick={capturePhoto}><Image src={photoicon}></Image></Button>
                                </Col>
                            </Row>
                            <div className="chat_container">
                                <ListGroup variant="flush">
                                    {chats.map((row, index) => {
                                        var cName = "text-right"
                                        if (row.sender != "You") {
                                            cName = ""
                                            if (index + 1 == chats.length) {

                                                return (
                                                    <ListGroup.Item key={index} className={cName}>
                                                        {row.sender}<br />
                                                        <Typewriter
                                                            onInit={(typewriter) => {
                                                                typewriter.changeDelay(40)
                                                                    .typeString(row.message)
                                                                    .callFunction(() => {
                                                                        __speaking = false
                                                                    })
                                                                    .start();
                                                            }}
                                                        />
                                                    </ListGroup.Item>
                                                )
                                            }
                                        }
                                        return (
                                            <ListGroup.Item key={index} className={cName}>{row.sender}<br />{row.message}</ListGroup.Item>
                                        )
                                    })
                                    }
                                    <div ref={messagesEndRef} ></div>
                                </ListGroup>
                            </div>
                            <InputGroup className="mb-3">
                                <FormControl
                                    placeholder="Your message for CRB-5"
                                    aria-label="Your message for CRB-5"
                                    aria-describedby="basic-addon2"
                                    value={message}
                                    onKeyPress={keyPressCheck}
                                    onChange={handleMessageChange}
                                />
                                <InputGroup.Append>
                                    <Button disabled={disabled} onClick={getNewBotAnswer} variant="outline-secondary">Send</Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </div>
                    </Container>
                </Col>
            </Row>
        </Layout>
    )
}
export default Chatbot
